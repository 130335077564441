import { Logo, BackgroundBottomLeft, BackgroundTopRight } from "./images";

export function MaintenancePage() {
    return (
        <div className="bg-[#f2f8ea] grid place-items-center relative p-4 min-h-[100dvh]">
            <img src={BackgroundBottomLeft} alt="" className="absolute bottom-0 left-0" />
            <img src={BackgroundTopRight} alt="" className="absolute top-0 right-0" />
            <div className="flex flex-col items-center gap-12">
                <img src={Logo} alt="TLN" />
                <div className="flex flex-col items-center gap-2">
                    <h1 className="font-500 color-[#273B29] text-[2rem] lg:mt-6 mt-2 mb-8">We'll be back soon</h1>
                    <p className="lg:text-[16px] text-[14px] pr-4 mb-4 text-center text-[#828282]">
                        Our portal is undergoing planned maintenance.
                        <br />
                        Please try again later.
                    </p>
                    <a href="https://lactationnetwork.com/" className="block rounded-full px-6 py-3 font-semibold md:w-auto w-full md:mb-0 mb-2 mt-4 bg-[#293d2a] hover:bg-[#506551] text-[#ffffff] hover:text-[#ffffff]" type="button">
                        Go to homepage
                    </a>
                </div>
            </div>
        </div>
    )
}