export type StepOneData = {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  babyArrived: string;
  twins: string;
  babyFirst: string;
  babyLast: string;
  babyDOB: string;
  babySex: string;
  dueDate: string;
};

export type StepTwoData = {
  primaryPlan: string;
  primaryPlanId: string;
  primaryIsLocked: boolean;
  primaryNumber: string;
  primaryGroup: string;
  primaryReferral: string;
  primaryNoTelehealth: boolean;
  primaryFront?: File;
  primaryBack?: File;
  subscriberRelationship: string;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberDateOfBirth: string;
  coverageInformation: string | null;

  primaryProvidedByEmployer: string;
  babyOnPrimary: string;
  babyInsurancePlan: string;
  babyInsurancePlanId: string;
  babyInsuranceNumber: string;
  babyInsuranceGroup: string;
  babyInsuranceReferral: string;
  babyInsuranceNoTelehealth: boolean;
  babyInsuranceHolderFirstName: string;
  babyInsuranceHolderLastName: string;
  babyInsuranceHolderDob: string;
  babyInsuranceFront?: File;
  babyInsuranceBack?: File;

  haveSecondary: string;
  secondaryPlan: string;
  secondaryPlanId: string;
  secondaryNumber: string;
  secondaryGroup: string;
  secondaryFront?: File;
  secondaryBack?: File;

  employer: string;
  employerId: string;
  employerAliasSelected: string;
  hasEmployerCode: string;
  employerCode: string;

  vaReferralNumber: string;
  vaCardFront?: File;
  vaCardBack?: File;

  formType: "vanity" | "nbf" | "main";
};

export type StepThreeData = {
  urgency: string;
  careLocation: string;
  vanityName: string;
  pairing: string;
  consultantName: string;
  smsAcknowledgement: boolean;
  privacyPolicyAcknowledgement: boolean;
  aobAcknowledgement: boolean;
};

export type FormData = StepOneData &
  StepTwoData &
  StepThreeData & {
    submitted: string;
    isLoading: boolean;
    formType: string;
  };

export type ImageFileData = {
  id: string;
  bytes: string;
  extension: string;
  mimeType: string;
  name: string;
  fileType: string;
};

export type InsuranceAlias = {
  id: string;
  name: string;
  insuranceProviderId: string;
  aliasName: string;
};

export type InsuranceProvider = {
  id: string;
  name: string;
  insuranceAliases: InsuranceAlias[];
  noTelehealth: boolean;
  coverageInformation: string | null;
};

export type EmployerAlias = {
  id: string;
  name: string;
  aliasName: string;
  aliasType: string;
  employerId: string;
};

export type Employers = {
  accountId: string;
  name: string;
  employerAliases: EmployerAlias[];
};

export type SelectOption = {
  label: string;
  value: string;
};

export type EmployerSelectOption = SelectOption & {
  employerAccountName: string;
};

export const FeatureNames = {
  Maintenance: "TLN.FeatureFlags.WebIntake.UI.Maintenance",
  Redirect: "TLN.FeatureFlags.WebIntake.UI.Redirect",
};
