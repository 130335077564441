import { useMultistepForm } from "./hooks/useMultistepForm";
import { FormEvent, useState, useEffect } from "react";
import { StepOneForm } from "./StepOne";
import { StepTwoForm } from "./StepTwo";
import { StepThreeForm } from "./StepThree";
import { StepFourForm } from "./StepFour";
import { ArrowIcon } from "./images/index";
import { ContactSidebar } from "./components/ContactSidebar";
import { Header } from "./components/Header";
import { v4 } from "uuid";
import { Footer } from "./components/Footer";
import {
  FormData,
  ImageFileData,
  InsuranceAlias,
  InsuranceProvider,
  EmployerAlias,
  Employers,
  EmployerSelectOption,
  FeatureNames,
} from "./types";
import { ThankYou } from "./ThankYou";
import { HowToRegIcon, ApartmentIcon } from "./images";
import { ErrorPage } from "./ErrorPage";
import { ValidatorProvider, useValidator } from "./hooks/useValidator";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import { MaintenancePage } from "./MaintenancePage";

const SUBMISSION_ID_HEADER = "x-tln-submission-id";
const myuuid = v4();

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};
const setCookie = (cookieName: string, cookieValue: string) =>
  (document.cookie = `${cookieName}=${cookieValue};path=/;`);
const deleteCookie = (cookieName: string) =>
  (document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`);

// Check URL for practiceId and employerLookupId
const searchParams = new URLSearchParams(document.location.search);
const insurerId = searchParams.get("insurerId") || "";
let practiceId = searchParams.get("practiceId") || "";
let employerLookupId = searchParams.get("employerLookupId") || "";

if (practiceId) {
  setCookie("practiceId", practiceId);
  deleteCookie("employerLookupId");
} else if (employerLookupId) {
  setCookie("employerLookupId", employerLookupId);
  deleteCookie("practiceId");
} else {
  practiceId = getCookie("practiceId") || "";
  employerLookupId = getCookie("employerLookupId") || "";
}

const INITIAL_DATA: FormData = {
  firstName: "",
  lastName: "",
  preferredName: "",
  email: "",
  phone: "",
  dateOfBirth: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  country: "United States",
  babyArrived: "",
  twins: "",
  babyFirst: "",
  babyLast: "",
  babyDOB: "",
  babySex: "",
  dueDate: "",

  primaryPlan: "",
  primaryPlanId: "",
  primaryIsLocked: false,
  primaryNumber: "",
  primaryGroup: "",
  primaryReferral: "",
  primaryNoTelehealth: false,
  subscriberRelationship: "",
  subscriberFirstName: "",
  subscriberLastName: "",
  subscriberDateOfBirth: "",

  primaryProvidedByEmployer: "",
  babyOnPrimary: "",
  babyInsurancePlan: "",
  babyInsurancePlanId: "",
  babyInsuranceNumber: "",
  babyInsuranceGroup: "",
  babyInsuranceReferral: "",
  babyInsuranceNoTelehealth: false,
  babyInsuranceHolderFirstName: "",
  babyInsuranceHolderLastName: "",
  babyInsuranceHolderDob: "",

  haveSecondary: "",
  secondaryPlan: "",
  secondaryPlanId: "",
  secondaryNumber: "",
  secondaryGroup: "",

  employer: "",
  employerAliasSelected: "",
  employerId: "",
  hasEmployerCode: "",
  employerCode: "",

  vaReferralNumber: "",

  urgency: "",
  careLocation: "",
  pairing: "",
  consultantName: "",
  smsAcknowledgement: false,
  privacyPolicyAcknowledgement: false,
  aobAcknowledgement: false,

  vanityName: "",
  submitted: "no",
  isLoading: false,
  coverageInformation: null,
  formType: practiceId ? "vanity" : employerLookupId ? "nbf" : "main",
};

let highestReachedIndex = 0;

function App() {
  const [data, setData] = useState(INITIAL_DATA);
  const [insOptions, setInsOptions] = useState([]);
  const [insProviders, setInsProviders] = useState([]);
  const [employerOptions, setEmployerOptions] = useState<
    EmployerSelectOption[]
  >([]);

  useEffect(() => {
    if (data.formType === "vanity") {
      fetch("/api/LactationPractice?id=" + practiceId, {
        headers: { [SUBMISSION_ID_HEADER]: myuuid },
      })
        .then((response) => response.json())
        .then((res) => {
          updateFields({ vanityName: res.name });
        })
        .catch((error) => console.log(error));
    }

    if (data.formType === "nbf") {
      fetch("/api/Employer/byLookup/" + employerLookupId, {
        headers: { [SUBMISSION_ID_HEADER]: myuuid },
      })
        .then((response) => response.json())
        .then((res) => {
          updateFields({
            employer: res.name,
            employerAliasSelected: res.name,
            employerId: res.accountId,
            primaryProvidedByEmployer: "yes",
          });
        })
        .catch((error) => console.log(error));
    }

    fetch("/api/Insurance", {
      headers: { [SUBMISSION_ID_HEADER]: myuuid },
    })
      .then((response) => response.json())
      .then((res) => {
        const providers = res.filter(
          (provider: InsuranceProvider) => provider.insuranceAliases.length > 0
        );
        setInsProviders(providers);
        const options = providers
          .flatMap((provider: InsuranceProvider) => {
            return provider.insuranceAliases;
          })
          .sort((a: InsuranceAlias, b: InsuranceAlias) =>
            a.aliasName.localeCompare(b.aliasName)
          )
          .map((alias: InsuranceAlias) => {
            if (insurerId && insurerId === alias.insuranceProviderId) {
              data.primaryPlanId = alias.insuranceProviderId;
              data.primaryPlan = alias.aliasName;
              data.primaryIsLocked = true;
            }
            return {
              label: alias.aliasName,
              value: alias.insuranceProviderId,
            };
          });
        setInsOptions(options);
      })
      .catch((error) => console.log(error));

    fetch("/api/Employers", {
      headers: { [SUBMISSION_ID_HEADER]: myuuid },
    })
      .then((response) => response.json())
      .then((res) => {
        const options: EmployerSelectOption[] = res
          .filter(
            (employers: Employers) => employers.employerAliases.length > 0
          )
          .flatMap((employer: Employers) => {
            return employer.employerAliases.map((alias) => {
              return {
                label: alias.aliasName,
                employerAccountName: employer.name,
                value: alias.employerId,
              };
            });
          })
          .sort((a: EmployerSelectOption, b: EmployerSelectOption) =>
            a.label.localeCompare(b.label)
          );
        setEmployerOptions(options);
      })
      .catch((error) => console.log(error));

    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  function updateFields(fields: Partial<FormData>) {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  }

  const valMethods = useValidator<FormData>(data);

  const {
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    validateFailed,
  } = useMultistepForm(
    [
      <StepOneForm {...data} updateFields={updateFields} />,
      <StepTwoForm
        {...data}
        updateFields={updateFields}
        insOptions={insOptions}
        employerOptions={employerOptions}
        insProviders={insProviders}
      />,
      <StepThreeForm {...data} updateFields={updateFields} />,
      <StepFourForm {...data} />,
    ],
    valMethods.validate
  );

  // google tag manager events for Mabbly
  const pushGtmEvent = (eventName: string) => {
    (window as any).dataLayer.push({
      event: eventName,
      employer_lookup_id: employerLookupId,
      form_type: data.formType,
      practice_id: practiceId,
    });
  };

  useEffect(() => {
    switch (currentStepIndex) {
      case 1:
        if (1 > highestReachedIndex) {
          pushGtmEvent("Insurance page (#2)");
          highestReachedIndex = 1;
        }
        break;
      case 2:
        if (2 > highestReachedIndex) {
          pushGtmEvent("Preferences page (#3)");
          highestReachedIndex = 2;
        }
        break;
      case 3:
        if (3 > highestReachedIndex) {
          pushGtmEvent("Confirmation page (#4)");
          highestReachedIndex = 3;
        }
        break;
      default:
        break;
    }
    if (currentStepIndex > highestReachedIndex) {
      highestReachedIndex = currentStepIndex;
    }
  }, [currentStepIndex]);

  useEffect(() => {
    if (data.submitted === "yes") {
      pushGtmEvent("Request Consultation");
    }
  }, [data.submitted]);

  async function mapFileData(file: File | undefined, fileType: string) {
    if (!file) return null;

    const base64 = (await getBase64(file)) as string;

    return {
      id: v4(),
      name: file?.name.split(".")[0],
      mimeType: file?.type,
      extension: file?.name.split(".")[1],
      bytes: base64.split(",").pop(),
      fileType: fileType,
    };
  }

  function getBase64(file: File | undefined) {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        resolve(e.target?.result);
      };
    });
  }

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (!isLastStep) return next();

    updateFields({ isLoading: true });

    let imgFiles: ImageFileData[] = [];

    if (data.primaryFront) {
      imgFiles.push(
        (await mapFileData(
          data.primaryFront,
          "InsuranceCardFront"
        )) as ImageFileData
      );
    }
    if (data.primaryBack) {
      imgFiles.push(
        (await mapFileData(
          data.primaryBack,
          "InsuranceCardBack"
        )) as ImageFileData
      );
    }
    if (data.babyInsuranceFront) {
      imgFiles.push(
        (await mapFileData(
          data.babyInsuranceFront,
          "InsuranceCardFront"
        )) as ImageFileData
      );
    }
    if (data.babyInsuranceBack) {
      imgFiles.push(
        (await mapFileData(
          data.babyInsuranceBack,
          "InsuranceCardBack"
        )) as ImageFileData
      );
    }
    if (data.secondaryFront) {
      imgFiles.push(
        (await mapFileData(
          data.secondaryFront,
          "InsuranceCardFront"
        )) as ImageFileData
      );
    }
    if (data.secondaryBack) {
      imgFiles.push(
        (await mapFileData(
          data.secondaryBack,
          "InsuranceCardBack"
        )) as ImageFileData
      );
    }
    if (data.vaCardFront) {
      imgFiles.push(
        (await mapFileData(
          data.vaCardFront,
          "InsuranceCardFront"
        )) as ImageFileData
      );
    }
    if (data.vaCardBack) {
      imgFiles.push(
        (await mapFileData(
          data.vaCardBack,
          "InsuranceCardBack"
        )) as ImageFileData
      );
    }

    let contacts = [
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        dateOfBirth: data.dateOfBirth,
        contactType: "Parent",
      },
    ];

    if (data.subscriberRelationship !== "Self") {
      contacts.push({
        firstName: data.subscriberFirstName,
        lastName: data.subscriberLastName,
        email: "",
        phone: "",
        dateOfBirth: data.subscriberDateOfBirth,
        contactType: "Subscriber",
      });
    }

    if (data.babyOnPrimary === "no") {
      contacts.push({
        firstName: data.babyInsuranceHolderFirstName,
        lastName: data.babyInsuranceHolderLastName,
        email: "",
        phone: "",
        dateOfBirth: data.babyInsuranceHolderDob,
        contactType: "Subscriber",
      });
    }

    let insurances = [
      {
        companyId: data.primaryPlanId,
        companyName: data.primaryPlan,
        policyNumber: data.primaryNumber,
        groupNumber: data.primaryGroup,
        insuranceType: "Primary",
      },
    ];

    if (data.haveSecondary === "yes") {
      insurances.push({
        companyId: data.secondaryPlanId,
        companyName: data.secondaryPlan,
        policyNumber: data.secondaryNumber,
        groupNumber: data.secondaryGroup,
        insuranceType: "Secondary",
      });
    }

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "submit" })
        .then(function (token: string) {
          const submitData = JSON.stringify({
            submissionOperationType: "Lactation",
            id: myuuid,
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            submissionOperationStatus: "InProcess",
            files: imgFiles,
            contacts: contacts,
            addresses: [
              {
                street: data.street,
                country: data.country,
                city: data.city,
                state: data.state,
                zipCode: data.zip,
                addressType: "HomeAddress",
              },
            ],
            insurances: insurances,
            employer: {
              employerId: data.employerId,
              employerName: data.employer,
              employerAlias: data.employerAliasSelected,
              employerCode: data.employerCode,
            },
            captchaToken: token,
            preferredName: data.preferredName,
            hasInsuranceCard: true,
            hasTwinsOrMultiples: data.twins === "yes",
            babyReferralRequired: data.babyInsuranceReferral === "Yes",
            primaryReferralRequired: data.primaryReferral === "Yes",
            hasAuthorizationReferralFromVeteransAffairs:
              data.vaReferralNumber !== "",
            veteransAffairsAuthorizationReferralNumber: data.vaReferralNumber,
            vanityIdentifier: data.vanityName,
            relationshipToSubscriber: data.subscriberRelationship,
            preferences: {
              lactationConsultantName:
                data.formType === "vanity"
                  ? data.vanityName
                  : data.consultantName,
              lactationGroupId: practiceId === "" ? null : practiceId,
              pairingPreference: data.pairing,
              visitUrgency: data.urgency.split(" - ")[0],
              visitType:
                data.careLocation === "No preference"
                  ? "Home Visit"
                  : data.careLocation,
            },
            acknowledgements: {
              privacyPolicyAcknowledged: true,
              smsAcknowledged: true,
              aobAcknowledged: true,
              aobAcknowledgedDate: new Date().toISOString(),
            },
            babies: [
              {
                firstName: data.babyFirst,
                lastName: data.babyLast,
                dateOfBirthOrExpectedDate:
                  data.babyArrived === "True" ? data.babyDOB : data.dueDate,
                hasArrived: data.babyArrived === "True",
                sexAssignedAtBirth: data.babySex,
                isOnParentsPrimaryInsurance: data.babyOnPrimary === "yes",
                insurances:
                  data.babyOnPrimary === "yes"
                    ? []
                    : [
                        {
                          companyId: data.babyInsurancePlanId,
                          companyName: data.babyInsurancePlan,
                          policyNumber: data.babyInsuranceNumber,
                          groupNumber: data.babyInsuranceGroup,
                          insuranceType: "Primary",
                        },
                      ],
              },
            ],
          });

          fetch("/api/Submission/", {
            method: "PUT",
            body: submitData,
            headers: {
              "Content-Type": "application/json",
              [SUBMISSION_ID_HEADER]: myuuid,
            },
          })
            .then((response: any) => {
              updateFields({
                submitted: response.ok ? "yes" : "error",
                isLoading: false,
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        });
    });
  }

  const pageContent = {
    heroMain: "EXPERT LACTATION <em>care</em> IN A FEW CLICKS",
    heroSub:
      "TLN checks your insurance coverage and pairs you with an expert lactation consultant, also known as an IBCLC.",
    submitText: "Get Paired",
  };
  if (data.formType === "vanity") {
    pageContent.heroMain = "CHECK <em>coverage</em> WITH TLN";
    pageContent.heroSub =
      "We'll check your insurance or sponsor coverage so you can receive care with your IBCLC.";
    pageContent.submitText = "Check Coverage";
  }
  if (data.formType === "nbf") {
    pageContent.heroMain = "REQUEST A CONSULTATION";
    pageContent.submitText = "Request A Consultation";
  }

  const [features, setFeatures] = useState<Record<string, boolean>>({});
  const [isLoadingFeatures, setIsLoadingFeatures] = useState(true);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      fetch("/api/Auth/feature-flags")
        .then((featureResponse) => featureResponse.json())
        .then((featureFlags) => {
          setFeatures(featureFlags.features);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoadingFeatures(false);
        });
    };

    fetchFeatureFlags();
  }, []);

  useEffect(() => {
    console.log(features, features?.[FeatureNames.Maintenance]);
  }, [features]);

  if (isLoadingFeatures) {
    return <></>;
  }

  return (
    <>
      {features?.[FeatureNames.Maintenance] ? (
        <MaintenancePage />
      ) : (
        <div className={`min-w-[400px] ${data.formType}`}>
          <Header />

          <div
            className={`md:container md:mx-auto mx-2 mt-4 mb-12 lg:grid lg:grid-cols-3 lg:gap-8 ${
              data.formType === "vanity"
                ? "vanity-hero lg:bg-[length:38%] md:bg-[length:30%] bg-[length:0]"
                : "bg-hero bg-contain bg-no-repeat rounded-tl-3xl"
            }`}
          >
            <div className="lg:col-span-2 lg:pl-20 lg:pr-12 md:px-8 px-4 pb-12 pt-2">
              {data.formType === "vanity" && (
                <div className="lg:text-[20px] md:text-[16px] text-[12px]">
                  <img
                    src={HowToRegIcon}
                    alt=""
                    className="inline-block relative bottom-px pr-2 md:w-[32px] w-[24px]"
                  />
                  You are paired with {data.vanityName}
                </div>
              )}
              {data.formType === "nbf" && (
                <div className="lg:mt-12 text-[#EFFFE9] lg:text-[20px] md:text-[16px] text-[12px]">
                  <img
                    src={ApartmentIcon}
                    alt=""
                    className="inline-block relative bottom-px pr-2 md:w-[32px] w-[24px]"
                  />
                  Consultation sponsored by:{" "}
                  <span className="uppercase md:inline block">
                    {data.employer}
                  </span>
                </div>
              )}
              <h1
                className={`lg:my-8 my-2 hero xl:text-[62px] lg:text-[50px] md:text-[36px] text-[18px] ${
                  data.formType === "vanity" && "lg:pr-4 md:pr-[30%]"
                }`}
                dangerouslySetInnerHTML={{ __html: pageContent.heroMain }}
              ></h1>
              <p
                className={`lg:my-8 my-2 hero xl:text-[22px] md:text-[20px] md:text-[16px] text-[12px] ${
                  data.formType === "vanity" && "lg:pr-4 md:pr-[30%]"
                }`}
              >
                {pageContent.heroSub}
              </p>

              {data.submitted === "no" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ValidatorProvider methods={valMethods}>
                    <form
                      className="bg-[#EFFFE9] py-8 rounded-3xl md:px-12 px-4 shadow-xl"
                      onSubmit={onSubmit}
                    >
                      <div className="grid grid-cols-4 gap-2 text-[12px]">
                        <div>
                          <div className="grid grid-cols-8 gap-1">
                            <div className="rounded-full w-[20px] h-[20px] bg-[#F8AC32] text-center leading-[20px] text-white">
                              1
                            </div>
                            <div className="relative top-[10px] mx-auto w-[85%] h-[2px] bg-[#E3E3E3] col-span-7 md:block hidden"></div>
                          </div>
                          <p className="pt-2 font-500 md:text-[12px] text-[10px]">
                            Parent and baby info
                          </p>
                        </div>
                        <div>
                          <div className="grid grid-cols-8 gap-2">
                            <div
                              className={`rounded-full w-[20px] h-[20px] ${
                                currentStepIndex > 0
                                  ? "bg-[#F8AC32]"
                                  : "bg-[#C2C2C2]"
                              } text-center leading-[20px] text-white`}
                            >
                              2
                            </div>
                            <div className="relative top-[10px] mx-auto w-[85%] h-[2px] bg-[#E3E3E3] col-span-7 md:block hidden"></div>
                          </div>
                          <p
                            className={`pt-2 font-500 ${
                              currentStepIndex < 1 && "text-[#C2C2C2]"
                            } md:text-[12px] text-[10px]`}
                          >
                            Insurance
                          </p>
                        </div>
                        <div>
                          <div className="grid grid-cols-8 gap-2">
                            <div
                              className={`rounded-full w-[20px] h-[20px] ${
                                currentStepIndex > 1
                                  ? "bg-[#F8AC32]"
                                  : "bg-[#C2C2C2]"
                              } text-center leading-[20px] text-white`}
                            >
                              3
                            </div>
                            <div className="relative top-[10px] mx-auto w-[85%] h-[2px] bg-[#E3E3E3] col-span-7 md:block hidden"></div>
                          </div>
                          <p
                            className={`pt-2 font-500 ${
                              currentStepIndex < 2 && "text-[#C2C2C2]"
                            } md:text-[12px] text-[10px]`}
                          >
                            Preferences
                          </p>
                        </div>
                        <div>
                          <div>
                            <div
                              className={`rounded-full w-[20px] h-[20px] ${
                                currentStepIndex > 2
                                  ? "bg-[#F8AC32]"
                                  : "bg-[#C2C2C2]"
                              } text-center leading-[20px] text-white`}
                            >
                              4
                            </div>
                          </div>
                          <p
                            className={`pt-2 font-500 ${
                              currentStepIndex < 3 && "text-[#C2C2C2]"
                            } md:text-[12px] text-[10px]`}
                          >
                            Confirmation
                          </p>
                        </div>
                      </div>

                      {step}

                      <div className="my-6">
                        {!isFirstStep && (
                          <button
                            className="rounded-full px-6 py-3 font-semibold border border-transparent hover:border-[#F8AC32] md:w-auto w-full md:mb-0 mb-2"
                            type="button"
                            onClick={back}
                          >
                            <img
                              src={ArrowIcon}
                              alt="Next"
                              className="inline-block relative bottom-px pl-4 scale-x-[-1]"
                            />
                            Previous
                          </button>
                        )}
                        <button
                          className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent disabled:opacity-30 disabled:cursor-default ${
                            !isFirstStep && "float-right"
                          } md:w-auto w-full`}
                          type="submit"
                          disabled={data.isLoading}
                        >
                          {data.isLoading
                            ? "Submitting . . ."
                            : isLastStep
                            ? pageContent.submitText
                            : "Next"}
                          <img
                            src={ArrowIcon}
                            alt="Next"
                            className={`inline-block relative bottom-px pl-6 ${
                              data.isLoading && "hidden"
                            }`}
                          />
                        </button>
                      </div>
                      {validateFailed && (
                        <span className="text-red-600">
                          {
                            "Some fields contain errors. Please review and try again."
                          }
                        </span>
                      )}
                    </form>
                  </ValidatorProvider>
                </LocalizationProvider>
              )}

              {data.submitted === "yes" && (
                <ThankYou myuuid={myuuid} formType={data.formType} />
              )}

              {data.submitted === "error" && (
                <ErrorPage
                  myuuid={myuuid}
                  navBack={() => {
                    updateFields({ submitted: "no", isLoading: false });
                  }}
                />
              )}
            </div>

            {data.submitted === "no" && (
              <ContactSidebar formType={data.formType} />
            )}
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
